import React from "react";
import { Helmet } from "react-helmet";

import CodeWhite from "../../static/images/code-white.inline.svg";
import CodeRed from "../../static/images/code-red.inline.svg";
import Title from "../../static/images/title.inline.svg";
import Subtitle from "../../static/images/subtitle.inline.svg";

const organizations = [
  {
    name: "NPower",
    slug: "npower",
    image: "logo-npower.svg",
    height: "50px",
    description:
      "NPower creates pathways to economic prosperity by launching digital careers for military veterans and young adults from underserved communities.  NPower operates in New York, Texas, California, Maryland, New Jersey, Missouri, Michigan and Toronto, Canada. Through an intensive 4 month training program and paid internships, NPower works to provide real-life technological and digital skills to its over 1200 annual participants.",
    url: "https://www.npower.org",
  },
  {
    name: "Year Up",
    slug: "yearup",
    image: "logo-yearup.svg",
    height: "54px",
    description:
      "Year Up works to close the Opportunity Divide by ensuring that young adults gain the skills, experience, and support that will empower them to reach their potential through careers and higher education. With locations across the US and 250+ corporate partners, Year Up provides young adults with technical & professional skills training followed by real-world work experience at a corporate internship. Over 30,000 students have participated in Year Up, with 90% of graduates employed or in school within 4 months of completing the program.",
    url: "https://www.yearup.org",
  },
  {
    name: "SMASH",
    slug: "smash",
    image: "logo-smash.png",
    height: "45px",
    description:
      "SMASH empowers its scholars (9th grade students) to be agents of change through a long term (3 year) project-based learning program to critically analyze problems facing their communities and create STEM based solutions utilizing a design thinking framework. With over 1300+ young people served to date, SMASH aims to eliminate the barriers facing Black, Latinx, and Native American students who have the desire to enter STEM and computing professions, while also broadening the economic opportunities of underrepresented communities of color.",
    url: "https://www.smash.org",
  },
  {
    name: "Black Girls CODE",
    slug: "black-girls-code",
    image: "logo-black-girls-code.v2.png",
    height: "85px",
    translateY: "-20px",
    description:
      "Black Girls CODE introduces computer coding lessons to young girls from underrepresented communities in programming languages. Black Girls CODE provides virtual coding workshops, mentorships, after-school, and summer programs that aim to bridge the digital divide and to build the self-confidence that comes from understanding the greatest tools of the 21st century across 15 cities in the US and South Africa. Black Girls CODE’s goal is to provide African-American youth with the skills to occupy some of the 1.4 million computing job openings expected to be available in the U.S. by 2020, and to train 1 million girls by 2040.",
    url: "https://www.blackgirlscode.com",
  },
];

export default () => {
  return (
    <div>
      <Helmet>
        {/* General tags */}
        <html lang="en" />
        <meta charSet="utf-8" />

        <title>Ask Your Developer by Jeff Lawson</title>
        <meta
          name="description"
          content="Jeff Lawson, software developer turned CEO of Twilio, shares a new approach for winning in the digital era. Unleash the creativity and productivity of the 25 million most important workers in the digital economy: software developers."
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/images/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/images/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/images/favicon-16x16.png"
        />
        <link rel="icon" type="image/svg+xml" href="/images/favicon.svg" />

        <link rel="preload" href="/images/hero-blast.svg" as="image" />

        <meta name="viewport" content="width=device-width,initial-scale=1.0" />

        {/* Schema.org tags */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Book",
            name:
              "Ask Your Developer: How to Harness the Power of Software Developers and Win in the 21st Century",
            author: {
              "@type": "Person",
              name: "Jeff Lawson",
            },
            url: "https://www.amazon.com/dp/0063018292?tag=imprintweb-20",
          })}
        </script>

        {/* OpenGraph tags */}
        <meta property="og:url" content="https://www.askyourdeveloper.com" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Ask Your Developer by Jeff Lawson, co-founder and CEO of Twilio"
        />
        <meta
          property="og:description"
          content="Releasing January 12, 2021. Pre-order your copy today."
        />
        <meta
          property="og:image"
          content="https://www.askyourdeveloper.com/images/og-image.v1.jpg"
        />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@askyourdev" />
        <meta
          name="twitter:title"
          content="Ask Your Developer by Jeff Lawson, co-founder and CEO of Twilio"
        />
        <meta
          name="twitter:description"
          content="Releasing January 12, 2021. Pre-order your copy today."
        />
        <meta
          name="twitter:image"
          content="https://www.askyourdeveloper.com/images/og-image.v1.jpg"
        />
      </Helmet>

      <div className="hero bg-ruby py-10">
        <div className={`blast blasted`}>
          <div className="correct">
            <div className={`scale down`}>
              <CodeWhite className={`done shake-constant`} />
              <CodeRed />
            </div>
          </div>
        </div>

        <div className={`band shadow-lg text-center px-6 py-7 blasted`}>
          <h1 className="text-white m-auto mb-4">
            <Title />
          </h1>
          <h2 className="text-banana m-auto">
            <Subtitle />
          </h2>
        </div>

        <div className="lead m-auto -mt-36 sm:-mt-40 md:-mt-44 sm:mr-auto sm:-mb-3 text-right relative z-20">
          <picture className="">
            <source
              media="(max-width: 576px)"
              srcSet="/images/hero-book-stack-sm.webp, /images/hero-book-stack-sm@2x.webp 2x"
              type="image/webp"
            />
            <source
              media="(min-width: 576px)"
              srcSet="/images/hero-book-stack.webp, /images/hero-book-stack@2x.webp 2x"
              type="image/webp"
            />
            <source
              media="(max-width: 576px)"
              srcSet="/images/hero-book-stack-sm.png, /images/hero-book-stack-sm@2x.png 2x"
              type="image/png"
            />
            <source
              media="(min-width: 576px)"
              srcSet="/images/hero-book-stack.png, /images/hero-book-stack@2x.png 2x"
              type="image/png"
            />
            <img
              src="/images/hero-book-stack.png"
              className="inline-block"
              // onError={(e) => { e.target.classList.add('error') }}
              alt="Ask Your Developer by Jeff Lawson"
            />
          </picture>
        </div>

        <h2 className="max-w-3xl text-white font-semibold m-auto text-xl md:text-2xl mt-10 mb-5 px-6 antialiased">
          Make an impact with your purchase
        </h2>

        <p className="max-w-3xl text-white m-auto md:text-lg mb-5 px-6 antialiased">
          All proceeds from <i>Ask Your Developer</i> will be given to these
          organizations who are working to ensure that developers and
          technologists of the future reflect the diverse world around us. In
          addition, Twilio is granting $100,000 from the{" "}
          <a href="https://www.twilio.org/impact-fund/" className="underline">
            Twilio Impact Fund
          </a>{" "}
          to each organization.
        </p>

        <p className="max-w-3xl text-white m-auto md:text-lg mb-10 px-6 antialiased">
          Your purchase directly supports this important work.{" "}
          <span className="font-semibold inline-block">
            Thank you for your support.
          </span>
        </p>
      </div>

      <div class="flex flex-wrap max-w-screen-lg m-auto my-10">
        {organizations.map((org, i) => {
          return (
            <div
              key={i}
              class="w-full text-lg leading-7 md:w-1/2 my-10 px-5 md:px-10"
            >
              <div className="h-24">
                <img
                  src={`/images/${org.image}`}
                  alt={`${org.name} logo`}
                  style={{
                    height: org.height,
                    transform: org.translateY
                      ? `translateY(${org.translateY})`
                      : "inherit",
                  }}
                />
              </div>

              {org.description}
              <div className="pt-5 font-semibold">
                <a href={org.url} target="blank" className="text-ruby">
                  {org.url}
                </a>
              </div>
            </div>
          );
        })}
      </div>

      <div className="bg-ruby">
        <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
          <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
            <div className="px-5 py-2">
              <a
                href="https://www.twilio.com/legal/tos"
                className="text-base text-white leading-6 text-gray-500 hover:text-gray-900"
              >
                Legal
              </a>
            </div>
            <div className="px-5 py-2">
              <a
                href="https://www.twilio.com"
                className="text-base text-white leading-6 text-gray-500 hover:text-gray-900"
              >
                Twilio.com
              </a>
            </div>
          </nav>
          <div className="mt-8">
            <p className="text-center text-white text-base leading-6 text-gray-400">
              &copy; 2020 Twilio Inc. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
